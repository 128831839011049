<template>
  <v-menu
    offset-y
    offset-x
    allow-overflow
    offset-overflow
    :nudge-left="$vuetify.breakpoint.smAndDown && '200'"
    max-width="260"
    v-model="show"
    :close-on-content-click="false"
    content-class="zg-select-border-radius"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-layout shrink v-on="on" v-bind="attrs">
        <DefaultButton
          color="icons"
          bgColor="transparent"
          icon
          class="ma-0 pa-0"
          small
        >
          <v-icon>mdi-plus-circle</v-icon>
        </DefaultButton>
      </v-layout>
    </template>
    <v-card>
      <v-layout column>
        <v-flex>
          <LabelTextField
            class="mt-3"
            label="Wyszukaj"
            :value.sync="pagination.search"
            :hideDetails="true"
            :searchIcon="true"
          ></LabelTextField>
        </v-flex>
        <v-flex>
          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
            class="my-0"
            height="2"
          ></v-progress-linear>
        </v-flex>
        <v-layout
          column
          :class="[
            'scroll-list',
            $vuetify.breakpoint.smAndDown ? 'mobile' : 'desktop',
          ]"
          my-1
        >
          <PredefinedRecordMenuDelegate
            v-for="record in records"
            :key="record.id"
            :record="record"
            :selectedRecords.sync="selectedRecords"
            @single="append"
            :noPairs="clientOnly"
          ></PredefinedRecordMenuDelegate>
        </v-layout>
        <v-layout>
          <v-spacer></v-spacer>
          <v-flex shrink>
            <DefaultButton
              @click.prevent="append"
              :disabled="selectedRecords.length == 0"
              :loading="loading"
              class="mx-4"
              >Dodaj</DefaultButton
            >
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
import PredefinedService from "@/services/predefined.service";
import CRUDMixin from "@/mixins/CRUDMixin";
import _ from "lodash";

export default {
  mixins: [CRUDMixin],
  props: {
    field: {},
    clientOnly: {
      type: Boolean,
      default: false,
    },
    cardType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pagination: {
        search: "",
        min: 1,
      },
      show: false,
      records: [],
      selectedRecords: [],
      all: [],
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    PredefinedRecordMenuDelegate: () =>
      import("@/components/delegates/PredefinedRecordMenuDelegate"),
  },
  watch: {
    show() {
      if (this.show) {
        this.all = [];
        this.records = [];
        this.selectedRecords = [];
        this.pagination.search = "";
        this.fetch();
      }
    },
    pagination: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PredefinedService.getPredefinedRecords(this.field.name, {
        card_type: this.cardType,
        ...this.pagination,
      })
        .then((x) => {
          this.records = x.results;
          if (this.all.length == 0) {
            this.all = x.results;
          }
          this.reset();
        })
        .catch(() => {
          this.handleError("Coś poszło nie tak.");
        });
    },
    append() {
      this.beforeRequest();
      if (this.clientOnly) {
        let texts = [];
        this.selectedRecords.forEach((record) => {
          const index = _.findIndex(this.all, { id: record.id });
          if (index !== -1) {
            texts.push(this.all[index].text);
          }
        });
        this.$emit("append:inline", texts);
      } else {
        this.$emit("append", this.selectedRecords);
      }
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-list {
  overflow: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &.desktop {
    max-height: 320px !important;
  }
  &.mobile {
    max-height: 96px !important;
  }
}
</style>